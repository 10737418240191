<template>
  <div>
    <md-toolbar class="md-primary mb-2" md-elevation="1">
        <h3 class="md-title" style="flex: 1">Fleet</h3>
        
        
        <md-button class="md-primary md-raised" @click="newVehicle">New vehicle</md-button>
        
    </md-toolbar>
    <md-progress-bar md-mode="indeterminate" v-if="isBusy"></md-progress-bar>
    
   
    <div class="md-content md-table md-theme-default" v-if="vehicles.length > 0 && !isBusy">
      <div class="md-content md-table-content md-scrollbar md-theme-default">
          <table>
              
                <tr class="md-table-row">
                  <th class="md-table-head">
                    <div class="md-table-cell-container">
                      Type
                    </div>
                    
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-cell-container">
                      Passengers
                    </div>
                    
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-cell-container">
                      Luggage
                    </div>
                    
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-cell-container">
                      Order
                    </div>
                    
                  </th>
                </tr>
              
              <draggable v-model="paginatedVehicles" tag="tbody" @change="listUpdated" class="table-content">
                <tr v-for="item in paginatedVehicles" :key="item.id" class="md-table-row" @click="rowClicked(item)">
                  <td class="md-table-cell">
                      <div class="md-table-cell-container white-space-no-wrap">
                        {{ item.name }}
                        <span class="mt-2 ml-2"  v-if="item.dmvCheck">

                          <md-chip  >
                              <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                              DMV Active: <b>{{item.dmvCheck.active}}</b>
                          </md-chip>
                          <md-chip  >
                              <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                          Base name: <b>{{item.dmvCheck.base_name }}</b>
                          </md-chip>
                          <md-chip  >
                              <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                          Base type: <b>{{item.dmvCheck.base_type }}</b>
                          </md-chip>
                          <md-chip  >
                              <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                          Vehicle: <b>{{item.dmvCheck.vehicle_year }}</b>
                          </md-chip>
                        </span>
                      </div>
                  </td>
                  <td class="md-table-cell">
                      <div class="md-table-cell-container">{{ item.passengers }}
                      </div>
                  </td>
                  <td class="md-table-cell">
                      <div class="md-table-cell-container">{{ item.luggage }}
                      </div>
                  </td>
                  <td class="md-table-cell">
                      <div class="md-table-cell-container">
                        <md-icon class="grab-cursor">drag_indicator</md-icon>
                      </div>
                  </td>
                </tr>
              </draggable>
          </table>
          <div class="md-layout pl-3 pr-3 md-alignment-center-left">
            <div class="md-layout-item">
              <vue-ads-pagination
                :total-items="total"
                :max-visible-pages="5"
                :page="activePage"
                :items-per-page="rowsPerPage"
                :loading="isBusy"
                @page-change="pageChange"
                @range-change="rangeChange"
            >
                <template slot-scope="props">
                    <div class="vue-ads-pr-2 vue-ads-leading-loose">
                        Showing Items {{ props.start }} to {{ props.end }} of {{ props.total }}
                    </div>
                </template>
                
                <template
                    slot="buttons"
                    slot-scope="props"
                >
                
                    <vue-ads-page-button
                        v-for="(button, key) in props.buttons"
                        :key="key"
                        v-bind="button"
                        @page-change="activePage = button.page"
                    />
                </template>
              </vue-ads-pagination>
            </div>
            <div class="md-layout-item md-size-10">
              <md-field class="mb-0">
                  <label>Rows</label>
                  <md-select v-model="rowsPerPage" md-dense>
                    <md-option :value="option" v-for="option in pageOptions" v-bind:key="option">{{option}}</md-option>
                  </md-select>
              </md-field>
            </div>
          </div>
        
         
      </div>
    </div>
    <md-empty-state
          md-icon="manage_search"
        v-if="vehicles.length == 0 && !isBusy"
        md-label="Add your first vehicle"
        :md-description="`Your entire fleet should be added here`">
        <md-button class="md-primary md-raised" @click="newVehicle">Add new vehicle</md-button>
    </md-empty-state> 
    <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
        <span>{{snackMessage}}</span>
        <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
    </md-snackbar>
    
  </div>
</template>

<script>
import firebase from '../../Firebase'
import CTableWrapper from '../base/Table'
import draggable from 'vuedraggable'
import 'vue-ads-pagination/dist/vue-ads-pagination.css';
import axiosInstance from 'axios'
 
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default {
  name: 'VehicleTypes',
  components: { 
    CTableWrapper, 
    draggable , 
    VueAdsPagination,
        VueAdsPageButton,
   
  },
  data() {
    return {
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      isBusy:true,
      vehicles: [],
      //vehicleTypes: [],
      //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
      //items: usersData,
      fields: [
        {key: 'type', label: 'Type'},
        {key: 'passengers', label: 'Passengers'},
        {key: 'luggage', label: 'Luggage'},
        {key: 'order', label: 'Order'},
      ],
      paginatedVehicles: [],
      activePage: 0,
      rowsPerPage: 10,
      total:0,
      pageOptions: [5,10,15,20,25,30],
      partialStart: null,
      partialEnd: null
      
      
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
    
      
  },
  mounted(){
   
    
    
  },
  created () {
    this.obtainViewResources()
    
    
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    async obtainViewResources() {
      this.isBusy = true
      
      await this.getFleet()
      await this.runDMVCheck()
      
      this.isBusy = false;
    },
    async getFleet() {
      let th = this;
      return new Promise((resolve, reject) => {
        var collection = th.getCollectionPath(th.user)
        collection.orderBy("order").onSnapshot((querySnapshot) => {
          th.vehicles = [];
          querySnapshot.forEach((doc) => {

            let dataToSave = doc.data();
            dataToSave.id = doc.id ;
            th.vehicles.push(dataToSave);
            

          });
          th.total = th.vehicles.length;
          resolve()
          
          
        });
      })
    },
    // TODO: Add dmv check
    getDMVStatus(plateNumber) {
        console.log('Get DMV Status')
        console.log(plateNumber)
        let plateToCheck = plateNumber.toUpperCase()
        let params = {
            

        }
        //tlcNumber = '4353'
        return new Promise((resolve, reject) => {
            axiosInstance.create({
                headers: {},                    
            //}).get('https://data.cityofnewyork.us/resource/5tub-eh45.json?$query=SELECT%0A%20%20%60license_number%60%2C%0A%20%20%60name%60%2C%0A%20%20%60status_code%60%2C%0A%20%20%60status_description%60%2C%0A%20%20%60expiration_date%60%2C%0A%20%20%60last_update_date%60%2C%0A%20%20%60last_update_time%60%0AWHERE%20%60license_number%60%20%3D%20%22' + tlcNumber + '%22',
            }).get('https://data.cityofnewyork.us/resource/8wbx-tsch.json?dmv_license_plate_number=' + plateToCheck ,
            {params: params})
            .then(response =>{
                console.log('Get DMV status' , response)
                resolve(response)
            }).catch(e => {
                console.log(e);
                reject()
            })
        })
    },
    async runDMVCheck() {
        // TODO: DMV Check method
        if(this.user.data.activeRole == 'driver' ) {

            for (let n = 0; n < this.vehicles.length; n++) {
                console.log(this.vehicles[n])
                if(
                    this.vehicles[n].mainInfo.plate && 
                    this.vehicles[n].mainInfo.plate != '' 
                  ) {
                    
                    let response = await this.getDMVStatus(this.vehicles[n].mainInfo.plate)
                    if(response.data.length > 0) {
                        this.$nextTick(()=> {

                            this.vehicles[n].dmvCheck = response.data[0]
                        })
                    }
                }
                
                
            }
        } 
    },
    getCollectionPath (user) {
      
      return firebase.firestore().collection('users').doc(this.user.data.uid).collection('fleet')
    },
    pageChange (page) {
        this.page = page;
    },
    
    rangeChange (start, end) {
        this.paginatedVehicles = this.vehicles.slice(start,end)
        this.partialStart = start;
        this.partialEnd = end;


    },
    listUpdated(ev){
      // console.log(ev);
      // console.log(ev.moved.element.type);
      // console.log('List updated');
      for (let i = this.partialStart  ; i < this.paginatedVehicles.length + this.partialStart ; i++) {
          this.vehicles.splice(i,1,this.paginatedVehicles[i - this.partialStart]);
        
      }
      
      this.updateOrder();
    },
    
    onPagination(){
      console.log('paginated');
    },
    rowClicked (item, index) {
      console.log(item);
      this.$router.push({path: `/app/fleet/${item.name}/${item.id}`})
    },
    
    newVehicle(){
      this.$router.push({path: `/app/fleet/new`})
    },
    
    updateOrder() {
      this.isBusy = true;
      let th = this;
      for (let index = 0; index < this.vehicleTypes.length; index++) {
          this.vehicles[index].order = index + 1;
          var collection = this.getCollectionPath(this.user)
          collection.doc(this.vehicles[index].id).update({
            order: index + 1
          })
          .then(function(){
              th.snackMessage = 'Vehicle types order updated';
              th.isBusy = false;
              th.showSnackbar = true;
          }).catch(function(error) {
             console.log("Error getting document:", error);
             th.isBusy = false;
          });
      }
      
    },
    
  }
}
</script>
<style>


.table-content tr {
  cursor:pointer;
}

</style>